import * as React from "react";
import { Helmet } from "react-helmet";
import PageLayout from "../PageLayout";
import SectionLayout from "../SectionLayout";
import HowWeCanHelp from "../components/HowWeCanHelp";
import { Link } from "gatsby";

const Privacy = () => {
  return (
    <PageLayout
      noButton
      title={"Privacy Policy"}
      navSize={"h-80"}
      titleStyles={"xl:mt-12 lg:mt-16 md:mt-12 sm:mt-20 mt-16"}
    >
      <Helmet>
        <title>EPG Solutions | Privacy Policy</title>
        <meta
          name="description"
          content="Contact us Privacy Policy Your privacy is important to us. It is EPG Solutions&#8217; policy to respect your privacy regarding any information we may collect from you across our website, http://www.epgsolutions.com.au, and other sites we own and operate. We only ask for personal information when we truly need it to provide a service to you."
        />
      </Helmet>
      <div className={"bg-epg-yellow h-5"} />
      <SectionLayout bgColor={"bg-epg-white"}>
        <p className={"pt-10 leading-7 font-exo2 text-epg-black"}>
          Your privacy is important to us. It is EPG Solutions’ policy to
          respect your privacy regarding any information we may collect from you
          across our website,{" "}
          <Link className={"font-bold hover:text-epg-yellow"} to={"https://www.epgsolutions.com.au"}>
            https://www.epgsolutions.com.au
          </Link>
          , and other sites we own and operate.
        </p>
        <p className={"my-5 leading-7 font-exo2 text-epg-black"}>
          We only ask for personal information when we truly need it to provide
          a service to you. We collect it by fair and lawful means, with your
          knowledge and consent. We also let you know why we’re collecting it
          and how it will be used.
        </p>
        <p className={"my-5 leading-7 font-exo2 text-epg-black"}>
          We only retain collected information for as long as necessary to
          provide you with your requested service. What data we store, we’ll
          protect within commercially acceptable means to prevent loss and
          theft, as well as unauthorized access, disclosure, copying, use or
          modification.
        </p>
        <p className={"my-5 leading-7 font-exo2 text-epg-black"}>
          We don’t share any personally identifying information publicly or with
          third-parties, except when required to by law.
        </p>
        <p className={"my-5 leading-7 font-exo2 text-epg-black"}>
          Our website may link to external sites that are not operated by us.
          Please be aware that we have no control over the content and practices
          of these sites, and cannot accept responsibility or liability for
          their respective privacy policies.
        </p>
        <p className={"my-5 leading-7 font-exo2 text-epg-black"}>
          You are free to refuse our request for your personal information, with
          the understanding that we may be unable to provide you with some of
          your desired services.
        </p>
        <p className={"my-5 leading-7 font-exo2 text-epg-black"}>
          Your continued use of our website will be regarded as acceptance of
          our practices around privacy and personal information. If you have any
          questions about how we handle user data and personal information, feel
          free to contact us.
        </p>
        <p className={"pb-12 leading-7 font-exo2 text-epg-black"}>
          This policy is effective as of 7 April 2020.
        </p>
      </SectionLayout>
      <HowWeCanHelp />
    </PageLayout>
  );
};

export default Privacy;
